import { rgba } from 'polished';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { globalTheme } from 'styles/global-theme';
import { Text } from '../text';
import { TextButton } from '../text-button';

export interface MessageProps {
  $compact?: boolean;
  backgroundColor?: keyof typeof globalTheme.colors;
  color?: keyof typeof globalTheme.colors;
}

export const Message = styled(Text)<MessageProps>`
  text-align: center;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 ${rgba(globalTheme.colors.darkGrey, 0.05)};

  ${({ $compact }) =>
    $compact &&
    css`
      padding: ${globalTheme.space[0]};
      border-radius: ${globalTheme.space[2]};
    `}

  ${TextButton} {
    color: inherit;
    font-weight: inherit;
    font-size: 100%;
  }
`;

export enum ErrorMessageType {
  pdfError = 'pdfError',
}

export const ErrorMessage: FC<{
  className?: string;
  type?: ErrorMessageType;
  variant?: 'default' | 'compact';
  backgroundColor?: keyof typeof globalTheme.colors;
  color?: keyof typeof globalTheme.colors;
}> = ({
  children,
  className,
  type,
  variant = 'default',
  backgroundColor = 'lightPink',
  color = 'pink',
}) => {
  return (
    <Message
      className={className}
      color={color}
      backgroundColor={backgroundColor}
      data-cy='error-message'
      data-state='error'
      data-type={type}
      $compact={variant === 'compact'}
    >
      {children}
    </Message>
  );
};

Message.defaultProps = {
  padding: 2,
  fontWeight: 'bold',
  color: 'darkGrey',
  backgroundColor: 'neutral',
  fontSize: 2,
  // @ts-ignore
  as: 'div',
};
