import enAU from 'date-fns/locale/en-AU';
import DatePicker, {
  ReactDatePickerProps,
  registerLocale,
} from 'react-datepicker';
import styled from 'styled-components';
import { globalTheme } from 'styles/global-theme';
registerLocale('en-AU', enAU);

export const DatePickerWrap = styled.div<{ $bookingCell?: boolean }>`
  input[type='text'] {
    width: 100%;
    min-width: 120px;
    height: ${(props) => (props.$bookingCell ? 'auto' : '44px')};
    padding: ${(props) =>
      props.$bookingCell
        ? '4px 8px'
        : `${globalTheme.space[0]} ${globalTheme.space[1]}`};
    font-size: ${(props) =>
      props.$bookingCell ? globalTheme.fontSizes.bookingMobile : 'inherit'};
    background-color: ${globalTheme.colors.white};
    border: 2px solid ${globalTheme.colors.neutral};
    border-radius: 8px;
    outline: 0;
    transition: border-color ${globalTheme.transitions.fast};

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  .react-datepicker {
    border: 2px solid ${globalTheme.colors.neutral};
    border-radius: 8px;
  }

  .react-datepicker__header {
    background-color: ${globalTheme.colors.white};
  }

  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--in-range {
    background-color: ${globalTheme.colors.blue};
    color: ${globalTheme.colors.white};
  }
`;

export interface DatepickerProps
  extends Omit<ReactDatePickerProps, 'selectsRange'> {
  selectsRange?: boolean;
  bookingCell?: boolean;
}

export const Datepicker = ({
  bookingCell,
  ...ReactDatePicker
}: DatepickerProps) => {
  return (
    <DatePickerWrap $bookingCell={bookingCell}>
      <DatePicker {...ReactDatePicker} />
    </DatePickerWrap>
  );
};
